<template>
  <div class="row col-md-12">
    <!-- Filter -->
    <div class="col-md-6">
      <validation-observer ref="filteruser" tag="form">
        <b-form>
          <div class="row">
            <!-- Filter Firstname -->
            <div class="col-md-8">
              <b-form-group label="Plat Nomor">
                <validation-provider #default="{ errors }" name="plate" rules="required">
                  <b-form-input v-model="filterPlate"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="-">
                <b-button type="button" variant="primary" @click="submitFilter">Cari</b-button>
              </b-form-group>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
    <div class="col-md-12">
      <loading v-if="Loading" />
        <b-table v-if="!Loading && tableData.length > 0" striped hover :items="tableData" :fields="tableFields" :per-page="perPage" :current-page="currentPage" show-empty>
          <template v-slot:cell(data)="row">
            <div class="d-flex" @click="editVehicle(row.item)">
              <!-- Foto -->
              <img :src="require('@/assets/images/avatars/car1.png')" v-if="row.item.imgurl == null && row.item.valid_img == false" style="width: 150px; height: 150px;" class="mr-3">
              <img :src="row.item.imgurl" style="width: 150px; height: 150px;" v-if="row.item.imgurl != null && row.item.valid_img == true">
              <!-- Informasi biodata pertama -->
              <div class="col-3">
                <p><strong>Owner Name:</strong> <br/><span style="color: yellow;">{{ row.item.owner_name }}</span></p>
                <p><strong>Plate:</strong> <br/>{{ row.item.plate }}</p>
              </div>
              <!-- Informasi biodata kedua -->
              <div class="col-3"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Model Name:</strong> <br/>{{ row.item.modelname }}</p>
                <p><strong>Name:</strong><br/> {{ row.item.name }}</p>
              </div>
              <div class="col-6"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <div class="col-md-6">
                  <b-alert variant="warning" show class="total-project-box" style="text-align: center;">
                      <p>Total Kriminal Kendaraan</p>
                      <!-- <h3 style="color: white;">0</h3> -->
                      <h3 style="color: white;">{{ row.item.total_kriminal }}</h3>
                  </b-alert>
                </div>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end" v-if="!Loading && tableData.length > 0">
          <b-pagination v-model="currentPage" :total-rows="tableData.length" :per-page="perPage"></b-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
  },
  data() {
    return {
      Loading: false,
      filterPlate: null,
      tableFields: [
        {key:'data',label:'Data Kendaraan'}],
      filterText: null,
      tableData: [],
      perPage: 5, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      showCatatanModal: false,
      keterangan: null,
      bukti1: null,
      bukti2: null,
      displayname:null,
      userid:null,
    };
  },
  methods: {
    init: function() {
      this.getCacheAvailable();
    },
    getCacheAvailable(){
      const cacheKey = 'dataKendaraan';
      const cachedData = localStorage.getItem(cacheKey);
      
      if (cachedData) {
        this.tableData = JSON.parse(cachedData);
        return;
      }
    },
    editVehicle(e){
      this.$router.push({ path: "/idp-kendaraan-detail/"+e.plate });
    },
    editCatatan(item) {
      this.displayname = item.fullname;
      this.userid = item.id;
      this.showCatatanModal = true;
    },
    getData() {
      this.Loading = true;
      axios.post("idp/vehicle/list-page",{plate:null}).then((response) => {
        this.tableData = response.data.data;
        localStorage.setItem('dataKendaraan', JSON.stringify(this.tableData)); // Save data to cache
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      });
    },
    submitFilter() {
      this.$refs.filteruser.validate().then((success) => {
        if(success){ 
          this.Loading = true
          axios.post("idp/vehicle/list-page",{plate:this.filterPlate}).then((response) => {
            this.tableData = response.data.data;
            localStorage.setItem('dataKendaraan', JSON.stringify(this.tableData)); // Update cache
          }).catch((error) => {
            if (error.status === 4000) {
              localStorage.clear();
              this.$router.push({ path: "/login" });
              ({
                title: "Login Expired",
                message: "Sesi Login Habis",
              });
            }
          }).finally(() => {
            this.Loading = false;
          });
        }
      });
    },
  },
  mounted() {
    this.init();
  }
}
</script>
<style scoped>
.rounded-image {
  border-radius: 50%;
}
</style>